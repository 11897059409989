import React, {useState} from "react";
import "./ResponseBlock.css";
import Button from "../../components/button/Button";
import InputWithError from "../../components/input/InputWithError";
import RequestResponseBody from "../../components/generator/RequestResponseBody";

const ResponseBlock = ({model, onRemove, onUpdate}) => {
    const [code, setCode] = useState(model.code || '');

    const validateCode = (value) => {
        const valid = value === '' || (!isNaN(parseFloat(value)) && isFinite(value) && parseFloat(value) > 0);
        return valid && value !== '';
    };

    const handleCodeChange = (value) => {
        const fieldValid = value === '' || (!isNaN(parseFloat(value)) && isFinite(value) && parseFloat(value) > 0);
        if (!fieldValid) return;

        model.valid = fieldValid && value !== '';
        model.setCode(value);
        setCode(value);
        onUpdate(model);
    };

    const updateValue = (value) => {
        model.setResponseBody(value);
    }

    return (
        <div className="response-block">
            <div className="vertical-block">
                <div className="response-block-meta-line">
                    <div>
                        <InputWithError
                            className="resp-status-code"
                            errorText="Must not be empty"
                            showError={!validateCode(code)}
                            value={code}
                            placeholder="Status code"
                            onChange={(e) => handleCodeChange(e)}
                        />
                    </div>
                    <select
                        disabled={true}
                        value="application/json"
                        className="content-type"
                    >
                        <option value="application/json">application/json</option>
                    </select>
                </div>
                <RequestResponseBody
                    model={model}
                    onUpdate={updateValue}
                />
            </div>
            <div className="vertical-block">
                <div>
                    <Button color="red" text="Remove" onClick={() => onRemove(model.id)}/>
                </div>
            </div>
        </div>
    );
};

export default ResponseBlock;
