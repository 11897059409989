class PathRequest {
    uri;
    pathParams;
    queryParams;

    constructor(uri, pathParams, queryParams) {
        this.uri = uri;
        this.pathParams = pathParams;
        this.queryParams = queryParams;
    }
}
export default PathRequest;