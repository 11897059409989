export class ResponseBlockModel {
    constructor(id, code = '', responseBody = '', valid = false) {
        this.id = id;
        this.code = code;
        this.responseBody = responseBody;
        this.valid = valid;
    }

    setCode(newCode) {
        this.code = newCode;
    }

    setResponseBody(newResponseBody) {
        this.responseBody = newResponseBody;
    }
}
