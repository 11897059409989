import {apiClient} from "../apiClient";
import {getAccessToken} from "../tokenService";
import GenerateOpenAPIResponse from "./GenerateOpenAPIResponse";

export const generateOpenAPI = async (request) => {
    try {
        const requestData = {
            type: request.type,
            path: request.path,
            summary: request.summary,
            responses: request.responses,
            requestBody: request.requestBody,
            headers: request.headers,
            queryParams: request.queryParams,
        };

        const response = await apiClient.post('https://openapi.ilinykh.org/api/v1/generate', requestData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getAccessToken(),
            }
        })

        return new GenerateOpenAPIResponse(response.data.balance, response.data.openAPI)
    } catch (error) {
        throw new Error(error.message);
    }
};