import React from 'react';
import './InputWithError.css';

const InputWithError = ({type = "text", value, onChange, placeholder, errorText, showError, className}) => {

    const handleChange = event => {
        onChange(event.target.value);
    }
    return (
        <div className="input-with-error">
            <input
                type={type}
                value={value}
                onChange={(e) => handleChange(e)}
                className={`input ${showError ? 'error' : ''} ${className}`}
                placeholder={placeholder}
            />
            <div className="error-message">{errorText}</div>
        </div>
    );
};

export default InputWithError;
