import React, {useState} from 'react';
import "./Parameter.css"
import InputWithError from "../../input/InputWithError";


const Parameter = ({model}) => {
    const [name, setName] = useState(model.name);
    const [example, setExample] = useState(model.example);
    const [required, setRequired] = useState(false);
    const [description, setDescription] = useState("")

    const handleUpdateName = (value) => {
        setName(value);
        model.name = value;
    };

    const handleUpdateExample = (updatedExample) => {
        setExample(updatedExample);
        model.example = updatedExample;
    };

    const handleUpdateDescription = (value) => {
        setDescription(value);
        model.description = value;
    };

    const handleUpdateRequired = (value) => {
        setRequired(value);
        model.required = value;
    };

    return (
        <div className="gen-header">
            <InputWithError
                value={name}
                placeholder={"Name"}
                errorText={model.nameErrorMessage}
                showError={!model.validateName()}
                onChange={(value) => handleUpdateName(value)}
            />
            <InputWithError
                value={example.value}
                placeholder={"Example"}
                errorText={model.exampleErrorMessage}
                showError={!model.validateExample()}
                onChange={(value) => handleUpdateExample(value)}
            />

            <input className={"input gen-header-input"}
                   placeholder={"Description"}
                   value={description}
                   onChange={(e) => handleUpdateDescription(e.target.value)}
            />
            <div className={""}>
                <label className={"gen-header-label"}>Required</label>
                <input type={"checkbox"}
                       className={"gen-header-required"}
                       placeholder={"Header's name"}
                       checked={required}
                       onChange={(e) => handleUpdateRequired(e.target.checked)}
                />
            </div>
        </div>
    );
}
export default Parameter;