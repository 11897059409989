import React, {useEffect, useState} from 'react';
import {ResponseBlockModel} from './ResponseBlockModel';
import Button from '../../components/button/Button';
import {getCustomerInfo} from '../../service/customerService';
import GenerateOpenAPIRequest from '../../service/generate/GenerateOpenAPIRequest';
import {generateOpenAPI} from '../../service/generate/GenerateService';
import InputWithError from '../../components/input/InputWithError';
import PathRequest from '../../service/generate/PathRequest';
import ParameterList from '../../components/generator/ParameterList';
import ResponseList from '../../components/generator/ResponseList';
import {PathViewModel} from './PathViewModel';
import RequestResponseBody from "../../components/generator/RequestResponseBody";

const OpenApiGenerator = () => {
    const [method, setMethod] = useState('POST');
    const [path, setPath] = useState(new PathViewModel(''));
    const [summary, setSummary] = useState('My awesome method');
    const [requestBody, setRequestBody] = useState('');
    const [blocks, setBlocks] = useState([new ResponseBlockModel(Date.now(), "200", "{}")]);
    const [headers, setHeaders] = useState([]);
    const [queryParams, setQueryParams] = useState([]);
    const [pathParams, setPathParams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openApi, setOpenApi] = useState('');
    const [balance, setBalance] = useState(0);

    const handlePathChange = (value) => {
        setPath(new PathViewModel(value));
    };

    const updateRequestBody = (value) => {
        setRequestBody(value);
    }

    useEffect(() => {
        getCustomerInfo().then((response) => {
            setBalance(response.balance);
        });
    }, [balance]);

    const handleGenerateV2 = async () => {
        setLoading(true);
        if (headers.filter((header) => !header.isValid()).length > 0 || !path.isValid()) {
            setLoading(false);
            return;
        }

        const responses = blocks.length === 0 ? undefined : blocks.map((block) => (
            {
                code: block.code,
                body: block.responseBody,
                description: "",
            }));

        const headersReq = headers.length ? headers.map(param => param.toRequest()) : undefined;
        const queryParamsReq = queryParams.filter.length ? queryParams.map(param => param.toRequest()) : undefined;
        const pathParamsReq = pathParams.filter.length ? pathParams.map(param => param.toRequest()) : undefined;

        let requestBodyObj = undefined;

        if (requestBody == null || requestBody.length > 0) {
            requestBodyObj = requestBody;
        }

        const request = new GenerateOpenAPIRequest(
            method,
            new PathRequest(path.value, pathParamsReq, queryParamsReq),
            summary.trim() || "My awesome method",
            headersReq,
            queryParamsReq,
            requestBodyObj,
            responses,
        )

        console.log(request)

        await generateOpenAPI(request).then((response) => {
            setBalance(response.balance);
            setOpenApi(response.openAPI);
        }).catch(ex => {
            setLoading(false);
        })
        setLoading(false);
    }

    const changeOpenApi = (value) => {
        setOpenApi(value);
    }

    return (
        <div className="container">
            <h1>Easy openAPI generator</h1>
            <div className="line">
                <select value={method} onChange={(e) => setMethod(e.target.value)}
                        className="dropdown line-element">
                    <option value="POST">POST</option>
                    <option value="GET">GET</option>
                    <option value="PUT">PUT</option>
                    <option value="PATCH">PATCH</option>
                    <option value="DELETE">DELETE</option>
                    <option value="OPTION">OPTION</option>
                </select>

                <InputWithError
                    value={path.value}
                    onChange={(e) => handlePathChange(e)}
                    placeholder="/api/v1/example"
                    errorText={path.errorMessage()}
                    showError={!path.isValid()}
                />

                <InputWithError
                    value={summary}
                    onChange={(e) => setSummary(e)}
                    placeholder="Summary"
                    error=""
                />
            </div>

            <ParameterList
                title="Headers"
                parameters={headers}
                setParameters={setHeaders}
            />

            <ParameterList
                title="Query parameters"
                parameters={queryParams}
                setParameters={setQueryParams}
            />

            <ParameterList
                title="Path parameters"
                parameters={pathParams}
                setParameters={setPathParams}
            />

            <div className="line">
                <div className='vertical-block'>
                    <h3>Request Body:</h3>
                    <RequestResponseBody
                        model={requestBody}
                        onUpdate={updateRequestBody}
                    />
                </div>
            </div>

            <ResponseList
                responses={blocks}
                setResponses={setBlocks}
            />

            <div className="vertical-block center">
                <div className="line"><label>Balance: {balance}</label></div>
                <div className="line"><Button text="Generate" disabled={loading} onClick={handleGenerateV2}/></div>
            </div>

            <div className="line">
        <textarea
            value={openApi}
            className="textarea response-textarea"
            placeholder='Openapi will be here'
            onChange={event => changeOpenApi(event.target.value)}
        />
            </div>
        </div>
    );
};

export default OpenApiGenerator;
