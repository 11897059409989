import React from 'react';
import './Button.css';

const Button = ({type = "button", text, onClick, color = 'blue', className = "", disabled = false}) => {
    return (
        <button
            className={`button button-${color} ${className} button-disabled-${disabled}`}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            {text}
        </button>
    );
};

export default Button;