export class PathViewModel {
    value;

    constructor(value) {
        this.value = value;
    }

    #validators = [
        (value) => value.trim().length > 0 ? undefined : "Path is required.",
        (value) => value.startsWith('/') ? undefined : "Path must start with a '/'.",
        (value) => !/[^a-zA-Z0-9/_-{}]/.test(value) ? undefined : "Only alphanumeric, '/', '_','{','}' and '-' are allowed.",
    ]

    isValid() {
        for (const validator of this.#validators) {
            const errorMessage = validator(this.value);

            if (errorMessage) {
                return false;
            }
        }

        return true;
    }

    errorMessage() {
        for (const validator of this.#validators) {
            const errorMessage = validator(this.value);

            if (errorMessage) {
                return errorMessage;
            }
        }

        return undefined;
    }
}