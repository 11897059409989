export class TopupResponse {
    constructor(order) {
        this.orderId = order.order;
        this.status = order.status;
        this.tokensRequested = order.tokensRequested;
        this.pricePerToken = order.pricePerToken;
        this.priceTotal = order.priceTotal;
        this.url = order.url;
    }

    // Method to update the status of the order
    updateStatus(newStatus) {
        this.status = newStatus;
    }

    // Method to get the current status of the order
    getStatus() {
        return this.status;
    }

    // Method to get the total price of the order
    getTotalPrice() {
        return this.priceTotal.amount + ' ' + this.priceTotal.currency;
    }

    // Method to get the URL for payment
    getPaymentUrl() {
        return this.url;
    }

    // Method to convert the object to a JSON string
    toJSON() {
        return JSON.stringify(this, null, 2);
    }
}