export class ParameterViewModel {
    nameErrorMessage = "";
    exampleErrorMessage = "";

    constructor(id, name, required, description, example) {
        this.id = id;
        this.name = name;
        this.required = required;
        this.description = description;
        this.example = example;
    }

    validateName() {
        const validators = [
            (value) => value.trim().length > 0 ? undefined : "Name is required.",
            (value) => !/[^a-zA-Z0-9/_\-{}]/.test(value) ? undefined : "Only alphanumeric, '/', '_','{','}' and '-' are allowed.",
        ]

        for (const validator of validators) {
            const errorMessage = validator(this.name);

            if (errorMessage) {
                this.nameErrorMessage = errorMessage;
                return false
            }
        }

        return true;
    }

    validateExample() {
        const validators = [
            (value) => value.trim().length > 0 ? undefined : "Example is required.",
        ]

        for (const validator of validators) {
            const errorMessage = validator(this.example);

            if (errorMessage) {
                this.exampleErrorMessage = errorMessage;
                return false;
            }
        }

        return true;
    }

    isValid() {
        return this.validateName() && this.validateExample();
    }

    toRequest() {
        return {
            name: this.name,
            required: this.required,
            example: this.example,
            description: this.description,
        }
    }

}