import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";

const EmailVerificationPage = () => {
    const {id} = useParams();
    const [verificationErrorMessage, setVerificationErrorMessage] = useState(null);

    useEffect(() => {
        if (id) {
            verifyEmail(id);
        } else {
            setVerificationErrorMessage("Invalid verification link")
        }
    }, []);

    const verifyEmail = async (id) => {
        const url = "https://openapi.ilinykh.org/api/public/v1/registration/verify";
        const body = {uuid: id}
        const config = {
            headers: {'Content-Type': 'application/json'}
        }

        try {
            await axios.put(url, body, config);
            setVerificationErrorMessage("Email successfully verified")
        } catch (error) {
            let message = "";

            switch (error.response.status) {
                case 404:
                    message = error.response.data.message;
                    break;
                case 400:
                    message = "Invalid verification link."
                    break;
                default:
                    message = "Unpredicted error. Contact to support: support@ilinykh.org"
                    break;
            }

            setVerificationErrorMessage(message);
        }

    };

    return (
        <div className="container">
            <label>{verificationErrorMessage}</label>
        </div>
    );
};
export default EmailVerificationPage;