import ResponseBlock from "../../pages/generator/ResponseBlock";
import Button from "../button/Button";
import React from "react";
import {ResponseBlockModel} from "../../pages/generator/ResponseBlockModel";

const ResponseList = ({responses, setResponses}) => {
    const handleAddBlock = () => {
        const newResponse = new ResponseBlockModel(Date.now()); // Create a new model instance
        setResponses([...responses, newResponse]);
    };

    const handleRemoveBlock = (id) => {
        setResponses(responses.filter(response => response.id !== id))
    }

    const handleUpdateBlock = (updatedResponse) => {
        setResponses(
            responses.map((response) =>
                response.id === updatedResponse.id ? updatedResponse : response
            )
        );
    }

    return (
        <div className='vertical-block'>
            <div>
                {responses.map((block) => <ResponseBlock
                    key={block.id}
                    model={block}
                    onRemove={handleRemoveBlock}
                    onUpdate={handleUpdateBlock}
                />)}
            </div>
            <div><Button color="green" text="Add response" onClick={handleAddBlock}/></div>
        </div>
    )
}
export default ResponseList;