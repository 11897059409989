import React from 'react';
import Button from '../button/Button';
import {ParameterViewModel} from "./parameter/ParameterViewModel";
import Parameter from "./parameter/Parameter";

const ParameterList = ({ title, parameters, setParameters}) => {
    const handleAddParameter = () => {
        const newParameter = new ParameterViewModel(Date.now(), '', false, '', '');
        setParameters([...parameters, newParameter]);
    };

    const handleRemoveParameter = (id) => {
        setParameters(parameters.filter(param => param.id !== id));
    };

    return (
        <div className="vertical-block">
            <div className="line"><h3>{title}</h3></div>
            <div>
                {parameters.map((param) => (
                    <div key={param.id}>
                        <Parameter model={param} />
                        <div className="line">
                            <Button color="red" text="Remove" onClick={() => handleRemoveParameter(param.id)} />
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <Button color="green" text={`Add ${title}`} onClick={handleAddParameter} />
            </div>
        </div>
    );
};

export default ParameterList;