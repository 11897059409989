import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TopBar.css';
import Button from "../button/Button"; // Import the CSS file

const TopBar = ({ onAuthChange, isAuth}) => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login'); // Redirect to the login page
    };

    const handleSignUp = async () => {
        navigate('/register');
    }

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        console.log('User logged out'); // Add any logout logic here
        onAuthChange(false);
        navigate('/login'); // Redirect to the login page after logging out
    };

    const handleProfile = () => {
        navigate('/profile');
    };

    const handlePrice = () => {
        navigate('/pricing');
    }


    return (
        <div className="top-bar">
            <div className='top-bar-wrapper'>
                <Button text={"Pricing"} color={"blue"} onClick={handlePrice}/>
                {!isAuth && (<Button text={"Login"} color={"blue"} onClick={handleLogin}/>)}
                {!isAuth && (<Button text={"Sign Up"} onClick={handleSignUp}/>)}
                {isAuth && (<Button text="Generator" color="blue" onClick={() => {navigate('/generator')}}/> )}
                {isAuth && (<Button text="Profile" color="blue" onClick={handleProfile}/> )}
                {isAuth && (<Button text={"Logout"}  color="red" onClick={handleLogout} /> )}
            </div>
        </div>
    );
};

export default TopBar;