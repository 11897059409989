import React, {useState} from 'react';

import './RegistrationPage.css'
import {register} from "../../service/registration/RegistrationService";
import RegistrationRequest from "../../service/registration/RegistrationRequest";
import InputWithError from "../../components/input/InputWithError";
import Button from "../../components/button/Button";

function RegistrationPage() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
    });
    const [registrationError, setRegistrationError] = useState(null);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    const setData = (field, value) => {
        setFormData({
            ...formData, [field]: value,
        })

        console.log(field, value);
    }

    const validateNull = (email) => {
        return email.trim().length > 0;
    }

    const validateForm = () => {

        return validateNull(formData.email) || validateNull(formData.firstName) || validateNull(formData.lastName) || validateNull(formData.password);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        setRegistrationSuccess(false);
        setRegistrationError(null);
        const req = new RegistrationRequest(formData.firstName, formData.lastName, formData.email, formData.password)
        try {
            const resp = await register(req);
            setRegistrationSuccess(true);
            console.log(resp);
        }
        catch (error) {
            setRegistrationSuccess(false);
            setRegistrationError(error);
        }
    }

    return (
        <div className='signup-container'>
            {registrationSuccess && (<div className='signup-card'>
                <label>Registration success. Check your email and verify.</label>
            </div>)}

            {!registrationSuccess && (<form className='signup-card' onSubmit={handleSubmit}>
                <h2>Sign up</h2>
                <InputWithError
                    value={formData.email}
                    type={"text"}
                    placeholder={"Email"}
                    errorText={"Email must not be empty."}
                    showError={!validateNull(formData.email)}
                    onChange={event => setData("email", event)}
                />
                <InputWithError
                    value={formData.firstName}
                    placeholder={"First name"}
                    errorText={"First name must not be empty."}
                    showError={!validateNull(formData.firstName)}
                    onChange={event => setData("firstName", event)}
                />

                <InputWithError
                    value={formData.lastName}
                    placeholder={"Last name"}
                    errorText={"Last name must not be empty."}
                    showError={!validateNull(formData.lastName)}
                    onChange={event => setData("lastName", event)}
                />

                <InputWithError
                    type={"password"}
                    value={formData.password}
                    placeholder={"Password"}
                    errorText={"Password must not be empty."}
                    showError={!validateNull(formData.password)}
                    onChange={event => setData("password", event)}
                />
                {registrationError!=='' && (<label className='reg-error-message'>{registrationError}</label>)}
                <Button type="submit" text={"Register"}/>
            </form>)}
        </div>
    );
}

export default RegistrationPage;
