import './RequestResponseBody.css'
import React, {useState} from "react";

const RequestResponseBody = ({onUpdate}) => {
    const [value, setValue] = useState('');
    const [textAreaId] = useState(Date.now().toString(36) + Math.random().toString(36).slice(2));

    const handlePayloadChange = (e) => {
        const value = e.target.value;
        setValue(value);
        onUpdate(value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            const textarea = e.target;
            const {selectionStart, selectionEnd} = textarea;
            const value = textarea.value;

            if (selectionStart !== selectionEnd) {
                const startOfFirstLine = value.lastIndexOf('\n', selectionStart - 1) + 1;
                const endOfLastLine = value.indexOf('\n', selectionEnd);
                const endOfSelection = endOfLastLine === -1 ? value.length : endOfLastLine;

                const selectedText = value.substring(startOfFirstLine, endOfSelection);
                const indentedText = selectedText
                    .split('\n')
                    .map((line) => `\t${line}`)
                    .join('\n');

                try {
                    textarea.setSelectionRange(startOfFirstLine, endOfSelection);
                    document.execCommand('insertText', false, indentedText);
                } catch {
                    textarea.setRangeText(indentedText, startOfFirstLine, endOfSelection, 'end');
                    textarea.dispatchEvent(new Event('input', {bubbles: true}));
                }

                setValue(textarea.value);
                onUpdate(value);

                textarea.selectionStart = startOfFirstLine;
                textarea.selectionEnd = startOfFirstLine + indentedText.length;
            } else {
                try {
                    document.execCommand('insertText', false, '\t');
                } catch {
                    textarea.setRangeText('\t', selectionStart, selectionEnd, 'end');
                    textarea.dispatchEvent(new Event('input', {bubbles: true}));
                }

                setValue(textarea.value);
                onUpdate(value);

                textarea.selectionStart = textarea.selectionEnd = selectionStart + 1;
            }
        }
    };

    const handleFormatJson = (event) => {
        const textarea = document.getElementById(textAreaId);
        if (value.length === 0) {
            return;
        }
        try {
            console.log(value)
            const parsed = JSON.parse(value);
            const formatted = JSON.stringify(parsed, null, 2);
            setValue(formatted);
            onUpdate(formatted);
            textarea.setSelectionRange(0, value.length);
            document.execCommand('insertText', false, formatted);
        } catch (e) {
            alert('Invalid JSON');
        }
    };

    return (
        <div style={{position: 'relative'}}>
            <textarea
                id={textAreaId.toString()}
                value={value}
                onChange={handlePayloadChange}
                onKeyDown={handleKeyDown}
                className="req-resp-textarea"
                placeholder='{ "key": "value" }'
            />
            <button
                onClick={(e) => handleFormatJson(e)}
                className={`req-resp-format-btn req-resp-format-btn-disabled-${value.length === 0}`}
                disabled={value.length === 0}
                onFocus={() => {
                    const textarea = document.getElementById(textAreaId.toString());
                    if (textarea) {
                        textarea.focus();
                    }
                }}
            >
                Format JSON
            </button>
        </div>
    );
};
export default RequestResponseBody;